import React, {useMemo} from 'react'
import {Col, Form, Row} from "antd";

import {filter} from "lodash";
import {renderInputFieldFromValueType} from "../../../utils/renderInputField";
import * as C from '../../../constants'
import SmartForm from "../SmartForm";
import {__} from '../../../utils/translationUtils'
import moment from "moment/moment";
import {appDefaults} from "../../../constants/globalConfiguration";

const getCustomControllerProps = ({valueType, onChange, dateOnly = false, minValue, maxValue}) => {

  if (valueType === 'DATE') {
    return {
      onChange: (newValue) => {
        let val = newValue

        if (dateOnly)
          val = moment(val).startOf('day')

        onChange(val)
      },
      showTime: !dateOnly ?
        {
          format: appDefaults.timeFormat,
          defaultValue: moment().startOf('day'),
          minuteStep: 15
        } :
        false,
      disabledDate: (date) => {
        if (minValue && date < minValue) {
          return true
        }
        if (maxValue && date < maxValue) {
          return true
        }
        return false
      },
      format: !dateOnly ? appDefaults.dateTimeFormat : appDefaults.dateFormat,
      getPopupContainer: trigger => trigger?.parentElement
    }
  }

  return {}
}

const ControllerType = (props) => {
  const Controller = renderInputFieldFromValueType(props.valueType)

  const customProps = getCustomControllerProps(props)

  return <Controller {...props} {...customProps}/>
}

export const getEnumValuesItems = (enumValues) => {
  if (!enumValues?.length) return []
  return enumValues.map((item) => ({
    value: item.value,
    label: item.toBeTranslated ? __(item.translationKey) : item.value
  }))
}

export const getEnumValuesMapItems = (enumValues) => {
  const enumMap = {}
  enumValues?.forEach((element) => {
    enumMap[element.id] = getEnumValuesItems(element.enumerationValues)
  })
  return enumMap
}

const SearchParameterForm = (props) => {
  const {
    initialValues = {},
    fields = [],
    getAttribute,
    form,
    enumerations,
    onValuesChange,
    onFinish
  } = props


  const enumItems = useMemo(() => getEnumValuesMapItems(enumerations), [enumerations])
  const displayableFields = useMemo(() => (fields.filter((f) => !f.hidden).sort((a, b) => a.position - b.position)), [fields])

  if (!filter(fields, (f) => !f.hidden).length) return (
    <div className="text-center">
      {__('There are no Search Fields available')}
    </div>
  )
  return (
    <SmartForm
      initialValues={initialValues}
      form={form}
      showSubmit={false}
      onValuesChange={onValuesChange}
      onFinish={(values) => {
        onFinish(values)
      }}
    >
      <Row gutter={24}>
        {displayableFields.map((field) => {
          const fieldAttr = getAttribute(field.attributeId)
          return (
            <Col span={12}>
              <SearchField
                mechanism={field.mechanism}
                multiSelect={field.multiSelect}
                type={fieldAttr.type}
                enumItems={enumItems[field.attributeId]}
                label={__(field.propertyLabel || fieldAttr.propertyLabel)}
                dataInterface={field.defaultValue}
                name={field.attributeId}
                required={field.required}
              />
            </Col>
          )
        })}
      </Row>
    </SmartForm>
  )
}

export const SearchField = ({name, label, mechanism, multiSelect, type, enumItems, required}) => {
  switch (mechanism) {
    case C.mechanismType.SPECIFIC_VALUE:
      return (
        <Form.Item label={label} name={name} rules={[{required: required, message: 'This filter is required.'}]}>
          <ControllerType
            valueType={type === C.VALUE_TYPES.ENUM_VALUE.value ? C.VALUE_TYPES.STRING.type : type}
            placeholder={__('None')}
            items={[{label: "", key: 1}]}
          />
        </Form.Item>
      )
    case C.mechanismType.RANGE:
      return (
        <Form.Item label={label}>
          <Form.Item name={`${name}From`} style={{display: 'inline-block', width: 'calc(50% - 12px)'}}
                     rules={[{required: required, message: 'This filter is required.'}]}>
            <ControllerType
              valueType={type}
              placeholder={__('From')}
              allowClear
            />
          </Form.Item>
          <span style={{display: 'inline-block', width: '24px', lineHeight: '32px', textAlign: 'center'}}
                rules={[{required: required, message: 'This filter is required.'}]}>
          </span>
          <Form.Item name={`${name}To`} style={{display: 'inline-block', width: 'calc(50% - 12px)'}}>
            <ControllerType
              valueType={type}
              placeholder={__('To')}
              allowClear
            />
          </Form.Item>
        </Form.Item>
      )
    case C.mechanismType.ENUMERATION:
      return (
        <Form.Item label={label} name={name} rules={[{required: required, message: 'This filter is required.'}]}>
          <ControllerType
            allowClear
            valueType={'ENUM_VALUE'}
            placeholder={__('None')}
            items={enumItems}
            maxTagCount={'responsive'}
            mode={multiSelect && 'multiple'}
          />
        </Form.Item>
      )
    default:
      return null
  }
}

export default SearchParameterForm
