import { T } from "../utils/translationUtils";

const errorCodesMap = {
  'User email already exists': T.userEmailAlreadyExists,
  'User is not yet active': T.userNotYetActive,
  'Bad Credentials': T.badCredentials,
  "User is expired": T.userNoLongerActive,
}

export default errorCodesMap
