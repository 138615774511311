import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import React from 'react'
import PropTypes from 'prop-types'
import {change, formValueSelector} from 'redux-form'
import {TableHeaderColumn} from 'react-bootstrap-table'
import objectAssign from 'object-assign'
import moment from 'moment'
import {Icon} from '@ant-design/compatible'

import {Button, DropdownButton, MenuItem} from 'react-bootstrap'
import Glyphicon from '@strongdm/glyphicon'
import {stringify} from 'query-string'
import {Tooltip} from 'antd'
import {addAsterisks, checkUserRights, renderResultInfo, searchToObject, showMessage} from '../utils/appHelper'

import * as config from '../constants/globalConfiguration'
import {deleteOrderType} from '../providers/ReduxProvider/actions/orderTypeActions'
import {api} from '../providers/ApiProvider'
import {hashHistory} from '../providers/HistoryProvider'
import {__} from '../utils/translationUtils'
import {ScrollableListView, StickyScrollableTable} from "../providers/StyleProvider/styles";
import {Modal} from "components";

const loadingBar = require('nprogress')
const BasicSearchForm = require('../components/BasicSearchForm').default

const initialState = {
  templates: [],
  deleteModal: {
    open: false,
    report: {
      name: '',
      id: 0
    }
  },
  data: [],
  isLoading: false,
  pagination: {
    pageNumber: 1,
    pageSize: 25,
    totalCount: 0
  },
  sort: 'name'
}

class OrderTypesPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {
    // initial results
    this.setQueryToState(this.updateHash.bind(this))
  }

  setQueryToState(cb) {
    const sq = searchToObject(this.props.location.search)
    this.props.change('basicSearchForm', 'q', sq.q)
    this.setState({
      sort: sq.sort || this.state.sort,
      pagination: objectAssign(this.state.pagination,
        {
          pageNumber: parseInt(sq.pageNumber) || this.state.pagination.pageNumber,
          pageSize: parseInt(sq.pageSize) || this.state.pagination.pageSize
        })
    }, () => {
      if (typeof cb !== 'undefined') {
        cb()
      }
    })
  }

  getParams() {
    const params = {...searchToObject(this.props.location.search)}
    return objectAssign({}, {
      sort: this.state.sort || params.sort,
      pageNumber: (parseInt(this.state.pagination.pageNumber) > -1) ? parseInt(this.state.pagination.pageNumber) : params.pageNumber,
      pageSize: this.state.pagination.pageSize || params.pageSize,
      q: this.props.q ? addAsterisks(this.props.q) : undefined
    })
  }

  updateHash() {
    // update url params
    hashHistory.push({
      ...this.props.location,
      search: stringify({
        pageNumber: this.state.pagination.pageNumber,
        pageSize: this.state.pagination.pageSize,
        sort: this.state.sort,
        q: this.state.q
      })
    }, this.getData())
  }

  search() {
    this.setState({
      q: this.props.q,
      pagination: objectAssign(
        this.state.pagination, {
          totalCount: 0,
          pageSize: this.state.pagination.pageSize,
          pageNumber: 1
        }
      )
    }, () => {
      this.updateHash()
    })
  }

  getData = () => {
    this.setState({isLoading: true})
    loadingBar.start()
    const params = {...this.getParams.bind(this)(), pageNumber: this.getParams().pageNumber}

    // params.status = 'disabled,enabled,reserved';

    return api.get('/orderTypes', {params})
      .then(
        (response) => {
          this.setState({
            data: response.data,
            isLoading: false,
            pagination: objectAssign(
              this.state.pagination, {
                totalCount: parseInt(response.headers['x-total-count']),
                pageSize: parseInt(response.headers['x-page-size']),
                pageNumber: parseInt(response.headers['x-page'])
              }
            )
          }, () => {
            loadingBar.done()
          })
        },
        (error) => {
          showMessage('error', error)
          this.setState({isLoading: false}, () => {
            loadingBar.done()
          })
        }
      )
  }

  onSortChange(sortName, sortOrder) {
    this.setState({sort: ((sortOrder === 'asc') ? '' : '-') + sortName}, function () {
      this.updateHash()
    })
  }

  onPageChange(page) {
    this.setState({
        pagination: objectAssign(
          this.state.pagination, {pageNumber: page}
        )
      },
      () => {
        this.updateHash()
      })
  }

  confirmDelete(report) {
    this.setState({deleteModal: {open: true, report}})
  }

  handledelete() {
    this.props.deleteOrderType(this.state.deleteModal.report.id).then(() => {
      this.getData()
    })
    // Close modal:
    this.closeDeleteModal()
  }

  closeDeleteModal() {
    this.setState({deleteModal: {...this.state.deleteModal, open: false}})
  }

  setPageSize(val) {
    this.setState({
      pagination: objectAssign(
        this.state.pagination, {
          totalCount: 0,
          pageSize: val,
          pageNumber: 1
        }
      )
    })
    this.updateHash()
  }

  renderPagination() {
    return (
      <DropdownButton
        onSelect={this.setPageSize.bind(this)}
        bsStyle="link"
        title={this.state.pagination.pageSize}
        className="btn-link"
        id="pageSize"
      >
        {config.pageSizeOptions.map((val, i) => <MenuItem key={i} eventKey={val}>{val}</MenuItem>)}
      </DropdownButton>
    )
  }

  trClassFormat(row) {
    return row.status // return class name.
  }

  render() {
    let userRights = []
    if (this.props.user) {
      userRights = this.props.user.rights
    }

    function linkFormater(cell, row) {
      if (row.accessRightsInfo.read) {
        return <Link className="stronger" to={`/orderTypes/${row.id}/edit`}>{row.name}</Link>
      }
      return row.name
    }

    function actionsFormater(cell, row) {
      return (
        <div className="actions text-left">
          {/*
          {' '}
          {row.accessRightsInfo.edit &&
            <Link
              to={`/orderTypes/${row.id}/edit`}
              className="btn btn-primary btn-sm btn-no-padding"
            >
              <Glyphicon glyph="edit"/>
              {__('Edit')} {' '}
            </Link>
          }
          */}
          {' '}
          {row.accessRightsInfo.delete
            && (
              <Tooltip title={__('delete')}>
                <div className="btn-no-padding btn-std" onClick={() => this.confirmDelete.bind(this)(row)}><Icon
                  className="icon-delete" type="delete" theme="outlined"/></div>
              </Tooltip>
            )}
        </div>
      )
    }

    const {rightMappings} = global.constants

    return (
      <ScrollableListView id="main-content">

        <h1 className="sticky">
          <div className="container">
            {__('Manage Order Types')}
          </div>
        </h1>

        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-lg-offset-1 col-sm-12">
              {checkUserRights(userRights, rightMappings.CAN_CREATE_ORDERTYPES)
                && (
                  <Link className="btn btn-success col-xs-12 col-sm-3 gutter-bottom" to="/orderTypes/create"><Glyphicon
                    glyph="plus"/>
                    {__('Create Order Type')}
                  </Link>
                )}

              <div className="col-xs-12 col-sm-6 col-lg-4">
                <BasicSearchForm
                  itemType="Order Type"
                  isLoading={this.state.isLoading}
                  initialValues={{q: searchToObject(this.props.location.search).q}}
                  onSubmit={this.search.bind(this)}
                />
              </div>
            </div>
          </div>

          {this.state.data
            && (
              <StickyScrollableTable
                data={this.state.data}
                remote
                pagination
                striped
                trClassName={this.trClassFormat}
                fetchInfo={{dataTotalSize: this.state.pagination.totalCount}}
                options={{
                  onPageChange: this.onPageChange.bind(this),
                  onSortChange: this.onSortChange.bind(this),
                  noDataText: this.state.isLoading ? __('loading_data') : __('NoDataFound'),
                  hideSizePerPage: false,
                  firstPage: 'First',
                  lastPage: 'Last',
                  sizePerPageList: config.pageSizeOptions,
                  paginationShowsTotal: renderResultInfo.bind(this),
                  page: this.state.pagination.pageNumber,
                  sizePerPage: this.state.pagination.pageSize
                }}
              >
                <TableHeaderColumn
                  dataSort
                  isKey
                  hidden
                  dataField="id"
                >
                  {__('Id')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataSort
                  dataField="name"
                  dataFormat={linkFormater.bind(this)}
                >
                  {__('Name')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataSort
                  dataField="color"
                  dataFormat={(value) => (
                    <div style={{
                      backgroundColor: value,
                      color: value, /* Little tricks to not set the height and the width ;) */
                      textAlign: 'center'
                    }}
                    >{value}
                    </div>
                  )}
                >
                  {__('Color')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="creator.id"
                  dataSort
                  dataFormat={(cell, row) => (
                    <a href={`#/users/${row.creator.id}`}>{row.creator.username}</a>
                  )}
                >
                  {__('creator')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="creationDatetime"
                  dataSort
                  dataFormat={(val) => moment(val).format(`${config.appDefaults.dateFormat} ${config.appDefaults.timeFormat}`)}
                >
                  {__('creationDateTime')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="modifier.id"
                  dataSort
                  dataFormat={(cell, row) => (
                    <a href={`#/users/${row.modifier.id}`}>{row.modifier.username}</a>
                  )}
                >
                  {__('LastModifiedBy')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="lastUpdateDatetime"
                  dataSort
                  dataFormat={(val) => moment(val).format(`${config.appDefaults.dateFormat} ${config.appDefaults.timeFormat}`)}
                >
                  {__('lastModified')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataFormat={actionsFormater.bind(this)}
                  width="65px"
                />
              </StickyScrollableTable>
            )}

          <Modal
            title={__('Confirm Deletion')}
            visible={this.state.deleteModal.open}
            size='small'
            onOk={this.handledelete.bind(this)}
            onCancel={this.closeDeleteModal.bind(this)}
            footer={
              <>
                <Button onClick={this.closeDeleteModal.bind(this)}>
                  {__('Back')}
                </Button>
                <Button onClick={this.handledelete.bind(this)} bsStyle="danger">
                  {__('Delete')}
                </Button>
              </>
            }
          >
            <p>
              {__('Are you sure you want to delete')} "<strong>{this.state.deleteModal.report.name}</strong>"?
            </p>
          </Modal>
        </div>

      </ScrollableListView>
    )
  }
}

OrderTypesPage.propTypes = {
  type: PropTypes.string,
  deleteOrderType: PropTypes.func,
  change: PropTypes.func,
  location: PropTypes.object,
  user: PropTypes.object,
  q: PropTypes.string,
  report: PropTypes.object
}

const selector = formValueSelector('basicSearchForm')
export default connect(
  (state) => ({
    user: state.user.data,
    q: selector(state, 'q')
  }),
  {deleteOrderType, change}
)(OrderTypesPage)
